import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../../components/portfolio";
import gladstone from "../../../images/gladstone.png";
import gladstoneMobile from "../../../images/gladstoneMobile.png";

const Design = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Website Design</h2>

    <h3>Gladstone Street Medical Clinic</h3>

    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        paddingTop: 20,
        paddingBottom: 20,
      }}
    >
      <div>
        <a href="http://www.gladstonestreet.com.au/" target="_blank">
          <div className="browser-mockup">
            <img style={{ maxWidth: "100%", height: 180 }} src={gladstone} />
          </div>
        </a>
      </div>
      <div style={{ marginTop: 5 }}>
        <a href="http://www.gladstonestreet.com.au/" target="_blank">
          <div className="mobile-mockup">
            <img style={{ maxWidth: "100%" }} src={gladstoneMobile} />
          </div>
        </a>
      </div>
    </div>

    <p>
      Gladstone Street Medical Clinic is a clinic located in Warragul, Victoria.
      The website I built them is a node.js app which uses Express JS and Jade
      for server side templating. Most of the website is static content, with
      some external widgets such as Google Maps and HotDoc, an online
      appointment booking service. The website uses a Bootstrap base for
      styling, with custom styling on top. The website is mobile-friendly and
      fully responsive.
    </p>

    <p>
      The node app is hosted on EC2, sitting behind a Cloudfront distribution
      for speed of access. Static resources such as images and styling files are
      properly hashed with a suitably long cache expiry.
    </p>

    <p>
      On the home page of the website is content pulled directly from the
      Clinic's Facebook page. This is a server side integration I wrote myself.
      Once every 30 minutes the server pulls data using Facebook's Graph API,
      and then locally cached for the website. Text and image posts are both
      fully supported.
    </p>

    <Link to="/portfolio/design/seizeTheDay/">Next</Link>
  </Scaffold>
);

export default Design;
