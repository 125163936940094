import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";

import Layout from "./layout";

import dotaCover from "../images/dotacover.jpg";
import clarityarchitecture from "../images/clarityarchitecture3.svg";
import gladstone from "../images/gladstone.png";
import gladstoneMobile from "../images/gladstoneMobile.png";
import conference from "../images/conference.png";
import mesite from "../images/mesite.png";

export default (props) => {
  return (
    <Layout>
      <Helmet>
        <link rel="preload" href={dotaCover} as="image" />
        <link rel="preload" href={clarityarchitecture} as="image" />
        <link rel="preload" href={gladstone} as="image" />
        <link rel="preload" href={gladstoneMobile} as="image" />
        <link rel="preload" href={conference} as="image" />
        <link rel="preload" href={mesite} as="image" />
      </Helmet>
      <div>
        <div
          style={{
            width: 250,
            border: "1px solid rgb(219, 219, 219)",
            float: "left",
          }}
        >
          <Link to="/portfolio/clarity/" style={{ textDecoration: "none" }}>
            <div
              style={{
                borderBottom: "1px solid rgb(219, 219, 219)",
                padding: 12,
                backgroundColor:
                  props.pathname === "/portfolio/clarity/"
                    ? "#f1f1f1"
                    : undefined,
              }}
            >
              Clarity Coach
            </div>
          </Link>
          {props.pathname === undefined ||
          !props.pathname.startsWith("/portfolio/clarity")
            ? null
            : [
                <Link
                  to="/portfolio/clarity/plan/"
                  key="0"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor: props.pathname.startsWith(
                        "/portfolio/clarity/plan"
                      )
                        ? "#f1f1f1"
                        : undefined,
                    }}
                  >
                    The Plan
                  </div>
                </Link>,
                <Link
                  to="/portfolio/clarity/silos/"
                  key="1"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor: props.pathname.startsWith(
                        "/portfolio/clarity/silos"
                      )
                        ? "#f1f1f1"
                        : undefined,
                    }}
                  >
                    Development
                  </div>
                </Link>,
                <Link
                  to="/portfolio/clarity/tech/"
                  key="2"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor: props.pathname.startsWith(
                        "/portfolio/clarity/tech"
                      )
                        ? "#f1f1f1"
                        : undefined,
                    }}
                  >
                    Technology Stack
                  </div>
                </Link>,
                <Link
                  to="/portfolio/clarity/growth/"
                  key="3"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor: props.pathname.startsWith(
                        "/portfolio/clarity/growth"
                      )
                        ? "#f1f1f1"
                        : undefined,
                    }}
                  >
                    Growth
                  </div>
                </Link>,
              ]}
          <Link to="/portfolio/design/" style={{ textDecoration: "none" }}>
            <div
              style={{
                borderBottom: "1px solid rgb(219, 219, 219)",
                padding: 12,
                backgroundColor:
                  props.pathname === "/portfolio/design/"
                    ? "#f1f1f1"
                    : undefined,
              }}
            >
              Website Design
            </div>
          </Link>
          {props.pathname === undefined ||
          !props.pathname.startsWith("/portfolio/design")
            ? null
            : [
                <Link
                  to="/portfolio/design/gladstoneStreetMedicalClinic/"
                  key="1"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor:
                        props.pathname ===
                        "/portfolio/design/gladstoneStreetMedicalClinic/"
                          ? "#f1f1f1"
                          : undefined,
                    }}
                  >
                    Medical Clinic
                  </div>
                </Link>,
                <Link
                  to="/portfolio/design/seizeTheDay/"
                  key="3"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor:
                        props.pathname === "/portfolio/design/seizeTheDay/"
                          ? "#f1f1f1"
                          : undefined,
                    }}
                  >
                    Seize the Day
                  </div>
                </Link>,
                <Link
                  to="/portfolio/design/portfolio/"
                  key="4"
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{
                      borderBottom: "1px solid rgb(219, 219, 219)",
                      padding: 12,
                      borderLeft: "4px solid #0074D9",
                      backgroundColor:
                        props.pathname === "/portfolio/design/portfolio/"
                          ? "#f1f1f1"
                          : undefined,
                    }}
                  >
                    Portfolio Site
                  </div>
                </Link>,
              ]}
          <Link to="/portfolio/movie/" style={{ textDecoration: "none" }}>
            <div
              style={{
                padding: 12,
                backgroundColor: props.pathname.startsWith("/portfolio/movie")
                  ? "#f1f1f1"
                  : undefined,
              }}
            >
              Movie Analytics
            </div>
          </Link>
        </div>
        <div
          style={{ width: "calc(100% - 250px)", float: "left", padding: 12 }}
        >
          {props.children}
        </div>
      </div>
    </Layout>
  );
};
